@font-face {
    font-family: "Blinker";
    src: local("Blinker"), url(./fonts/Blinker-Regular.ttf) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Blinker";
    src: local("Blinker ExtraLight"), url(./fonts/Blinker-ExtraLight.ttf) format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Blinker";
    src: local("Blinker ExtraLight"), url(./fonts/Blinker-Light.ttf) format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Blinker";
    src: local("Blinker SemiBold"), url(./fonts/Blinker-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Blinker";
    src: local("Blinker"), url(./fonts/Blinker-Bold.ttf) format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Open Sans";
    src: local("Open Sans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Blinker";
    src: local("Open Sans SemiBold"), url(./fonts/OpenSans-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

html {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    min-height: 100vh;
    font-size: 14px;
}

*, *:before, *:after {
    box-sizing: inherit;
    font-family: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    box-shadow: 0 0 0 2px transparent;
}

*:focus {
    outline: none;
    box-shadow: 0 0 0 2px black;
}

body {
    margin: 0;
    min-height: 100vh;
    color: #03385C;
    font: normal normal normal 16px "Open Sans", sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    word-break: break-word;
}

strong {
    font: normal normal normal 26px Blinker, sans-serif;
}

iframe {
    border: 0;
}

.report-style-class {
    height: 600px;
    margin: 1% auto;
    width: 100%;
}
